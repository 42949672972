import React, { useEffect } from 'react';
import NAV_DATA, { SecondaryNavItem } from '@zola-helpers/client/dist/es/constants/navConstants';

import { NavMapper } from '~/components/navV2/NavMapper/NavMapper';

import './navSecondary.less';

const NavSecondary = ({
  secondaryNavData = [],
  activeLinkId,
}: {
  secondaryNavData: SecondaryNavItem[];
  activeLinkId: string;
}): JSX.Element => {
  useEffect(() => {
    if (secondaryNavData.length)
      window.dispatchEvent(new CustomEvent(NAV_DATA.EVENTS.NAV_SECONDARY_RENDERED));
  }, [secondaryNavData]);
  return <NavMapper navData={secondaryNavData} type="Secondary" activeLinkId={activeLinkId} />;
};

export default NavSecondary;
