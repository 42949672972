import React, { useState, useEffect } from 'react';
import mapNavConstantsToTitle from '~/util/mapNavConstantsToTitle';
import NAV_DATA from '@zola-helpers/client/dist/es/constants/navConstants';
import type { PrimaryNavIdentifier } from '@zola-helpers/client/dist/types/constants/navConstants';
import { usePrevious } from '@zola/zola-ui/src/hooks';
import NavContext from './context';
import useScrollPosition from '../../../hooks/useScrollPosition';

type NavScrollContainerProps = {
  activeLinkId?: PrimaryNavIdentifier;
  disablePrimaryCollapse?: boolean;
  emitDirectionEvent?: boolean;
};

const NavScrollContainer: React.FC<NavScrollContainerProps> = ({
  children,
  activeLinkId,
  disablePrimaryCollapse = false,
  emitDirectionEvent = false,
}) => {
  const [hidePrimarySection, setHidePrimarySection] = useState(false);

  const { direction } = useScrollPosition(emitDirectionEvent);
  const prevDirection = usePrevious(direction);

  useEffect(() => {
    const shouldHide = direction === 'down';
    if (prevDirection !== direction) {
      if (!disablePrimaryCollapse) {
        setHidePrimarySection(shouldHide);
      }
    }
  }, [disablePrimaryCollapse, prevDirection, direction]);

  let finalLinkId = activeLinkId;
  if (
    !activeLinkId &&
    typeof window !== 'undefined' &&
    window?.location?.pathname?.indexOf('/expert-advice') > -1
  ) {
    finalLinkId = NAV_DATA.PRIMARY.ADVICE;
  }
  const navContextValue = {
    activeLinkId: finalLinkId,
    hidePrimarySection,
    activeParent: activeLinkId ? mapNavConstantsToTitle(activeLinkId) : undefined,
  };

  return <NavContext.Provider value={navContextValue}>{children}</NavContext.Provider>;
};

export default NavScrollContainer;
