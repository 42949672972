import experimentFlags, { HOLIDAY_SHOPS } from '~/util/experimentFlags';
import {
  PrimaryNavIdentifier,
  SecondaryNavIdentifier,
  SecondaryNavItem,
} from '@zola-helpers/client/dist/es/constants/navConstants';

import type { WStoreNavView } from '@zola/svc-web-api-ts-client';
import type { UserContext } from '@zola-helpers/client/dist/es/@types';
import giftNavIcon from '~/assets/images/navigation/gift.svg';

const homeStorePrimaryNav = (
  navData: WStoreNavView = { categories: [], brands: [] },
  userContext: UserContext
): SecondaryNavItem[] => {
  const businessUnit = 'HOME_STORE';
  const isHolidayEnabled = experimentFlags.getFlag(HOLIDAY_SHOPS, userContext);
  // Construct featured brands
  const featuredBrands = (navData.brands || []).slice(0, 7);
  const featuredBrandsItems: SecondaryNavItem[] = featuredBrands.map((featuredBrand, index) => ({
    title: featuredBrand.title || '',
    path: featuredBrand.path || '',
    position: index + 1,
    businessUnit,
  }));
  featuredBrandsItems.push({
    title: 'View All',
    path: '/shop/brands',
    position: featuredBrands.length + 1,
    businessUnit,
  });

  // Construct categories
  const categories: SecondaryNavItem[] = (navData.categories || []).map((category, index) => {
    const children = category.children?.map((child, childIndex) => {
      return {
        ...child,
        position: childIndex + 1,
        businessUnit,
        children: child.children?.map((grandchild, grandchildIndex) => ({
          ...grandchild,
          position: grandchildIndex + 1,
          businessUnit,
        })),
      };
    });
    return {
      identifier: category?.path?.replace('/shop/category/', ''),
      title: category.title,
      path: category.path,
      showCaret: false,
      children,
      centerDropdown: true,
      dockRight: false,
      parent: PrimaryNavIdentifier.REGISTRY,
      position: index + 4, // after Brands
      businessUnit,
    } as SecondaryNavItem;
  });

  return [
    {
      identifier: SecondaryNavIdentifier.NEW_ARRIVALS,
      title: 'New Arrivals',
      path: '/shop/collection/new-arrivals',
      parent: PrimaryNavIdentifier.REGISTRY,
      isActive: false,
      id: SecondaryNavIdentifier.NEW_ARRIVALS,
      children: [],
      position: 1,
      businessUnit,
    },
    {
      identifier: SecondaryNavIdentifier.COLLECTIONS,
      title: 'Collections',
      path: '/shop/gift-collections/style-collections',
      icon: isHolidayEnabled ? giftNavIcon : undefined,
      showCaret: true,
      parent: PrimaryNavIdentifier.REGISTRY,
      isActive: false,
      id: SecondaryNavIdentifier.COLLECTIONS,
      position: 2,
      businessUnit,
      children: isHolidayEnabled
        ? [
            {
              title: 'Most Wanted Gifts',
              path: '/shop/collection/most-wanted',
              children: [],
              position: 1,
              businessUnit,
            },
            {
              title: 'Stocking Stuffers',
              path: '/shop/collection/stocking-stuffers',
              children: [],
              position: 2,
              businessUnit,
            },
            {
              title: 'Gifts Under $100',
              path: '/shop/collection/gifts-under-100',
              children: [],
              position: 3,
              businessUnit,
            },
            {
              title: 'Gifts Under $200',
              path: '/shop/collection/gifts-under-200',
              children: [],
              position: 4,
              businessUnit,
            },
            {
              title: 'Splurge Worthy Gifts',
              path: '/shop/collection/splurge-worthy-gifts',
              children: [],
              position: 5,
              businessUnit,
            },
            {
              title: 'Cozy Home',
              path: '/shop/collection/cozy-home3',
              children: [],
              position: 6,
              businessUnit,
            },
            {
              title: 'Holiday Hosting & Entertaining',
              path: '/shop/collection/gifts-for-entertainers',
              children: [],
              position: 7,
              businessUnit,
            },
          ]
        : [
            {
              title: 'New Arrivals',
              path: '/shop/collection/new-arrivals',
              children: [],
              position: 1,
              businessUnit,
            },
            {
              title: 'Best Sellers',
              path: '/shop/collection/best-sellers1',
              children: [],
              position: 2,
              businessUnit,
            },
            {
              title: 'Upgrade Your Home',
              path: '/shop/collection/upgrade-your-home',
              children: [],
              position: 3,
              businessUnit,
            },
            {
              title: 'Ultimate Kitchen Essentials',
              path: '/shop/collection/top-kitchen-essentials',
              children: [],
              position: 4,
              businessUnit,
            },
            {
              title: 'Entertaining Must Haves',
              path: '/shop/collection/easy-entertaining',
              children: [],
              position: 5,
              businessUnit,
            },
            {
              title: 'Build Your Bar',
              path: '/shop/collection/build-your-bar',
              children: [],
              position: 6,
              businessUnit,
            },
            {
              title: 'Bedroom Upgrades',
              path: '/shop/collection/bedroom-upgrades-under-100',
              children: [],
              position: 7,
              businessUnit,
            },
          ],
    },
    {
      identifier: SecondaryNavIdentifier.BRANDS,
      title: 'Brands',
      path: '/shop/brands',
      children: [
        {
          title: 'Featured Brands',
          path: '/shop/brands',
          children: featuredBrandsItems,
          position: 1,
          businessUnit,
        },
      ],
      showCaret: true,
      parent: PrimaryNavIdentifier.REGISTRY,
      isActive: false,
      id: SecondaryNavIdentifier.BRANDS,
      position: 3,
      businessUnit,
    },
    ...categories,
    {
      identifier: SecondaryNavIdentifier.SALE,
      title: 'Sale',
      path: '/shop/sale',
      dockRight: false,
      parent: PrimaryNavIdentifier.REGISTRY,
      isActive: false,
      id: SecondaryNavIdentifier.SALE,
      position: 10,
      children: [],
      businessUnit,
    },
  ];
};

export default homeStorePrimaryNav;
