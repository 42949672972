import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { TagV2Size, TagV2Variant } from '@zola/zola-ui/src/components/Tag/TagV2';
import type { WTopNavMessageView } from '@zola/svc-web-api-ts-client';
import { COLORS3 } from '@zola/zola-ui/src/styles/emotion';
import { toastsActions } from '@zola-helpers/client/dist/es/redux/toasts';

import { showModal } from '~/actions/ModalActions';
import { getTopNavDiscountTerms } from '~/client/v1/topNavMessages';
import { BannerText, BannerContainer, CTAText, StyledTag, TermsLink } from './Banner.styles';

export type BannerProps = {
  banner?: WTopNavMessageView;
  isLeftAligned: boolean;
  showAnimation: boolean;
};

export const Banner = (props: BannerProps): JSX.Element => {
  const { banner, isLeftAligned, showAnimation } = props;
  const dispatch = useDispatch();

  if (!banner) {
    return <Fragment />;
  }

  const {
    message,
    text_color: color = COLORS3.WHITE_100,
    link_text: cta,
    link: ctaLink,
    label,
    promotion_code: promotionCode,
    id,
  } = banner;

  const getPromoTermsAndShowModal = (e: React.MouseEvent) => {
    e.preventDefault();
    if (id) {
      getTopNavDiscountTerms(id)
        .then((response) => {
          if (response) {
            dispatch(
              showModal(
                'PROMO_TERMS_MODAL',
                { termsAndConditions: response.terms_and_conditions, title: response.label },
                { size: 'sm' }
              )
            );
          }
        })
        .catch(() =>
          dispatch(
            toastsActions.negative({
              headline: `Unable to load terms. Please try again.`,
            })
          )
        );
    }
  };
  return (
    <BannerContainer href={ctaLink} isLeftAligned={isLeftAligned} showAnimation={showAnimation}>
      {label && (
        <StyledTag size={TagV2Size.SMALL} variant={TagV2Variant.WHITE}>
          {label}
        </StyledTag>
      )}
      {message && <BannerText color={color} dangerouslySetInnerHTML={{ __html: message }} />}
      {promotionCode && (
        <TermsLink
          subtle
          noUnderline
          textcolor={color}
          role="button"
          onClick={getPromoTermsAndShowModal}
        >
          Terms
        </TermsLink>
      )}
      {cta && ctaLink && <CTAText textcolor={color}>{cta}</CTAText>}
    </BannerContainer>
  );
};
