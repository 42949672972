import React, { useContext } from 'react';
import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import signupLinkCallback, {
  isRegistry,
} from '~/components/common/auth/AuthModal/helpers/signupLinkCallback';
import { trackNavigationClicked } from '@zola/tracking-contracts/src/tracking';
import { transformNavigationLevel } from '~/util/trackNavigationClickedHelpersV2';

import './navLoginOrSignUp.less';
import type { NavLoginOrSignUpDispatchProps } from '~/components/navV2/NavLoginOrSignUp/index';
import NavContext from '~/components/navV2/NavScrollContainer/context';

type NavLoginOrSignUpProps = NavLoginOrSignUpDispatchProps;
const NavLoginOrSignUp = ({ onShowLogin }: NavLoginOrSignUpProps): JSX.Element => {
  const { activeLinkId } = useContext(NavContext);

  const getButtonText = () => {
    return isRegistry(activeLinkId) ? 'Start your registry' : 'Get started';
  };

  const handleClickTrack = () => {
    const ctaText = getButtonText();
    trackNavigationClicked({
      business_category: 'UNATTRIBUTED',
      business_unit: 'UNATTRIBUTED',
      navigation_level_1: transformNavigationLevel(ctaText),
      navigation_level_1_position: 2,
      navigation_type: 'GLOBAL',
    });
    if (signupLinkCallback(activeLinkId)) onShowLogin();
  };

  return (
    <ButtonV3 className="nav-signup" onClick={handleClickTrack}>
      {getButtonText()}
    </ButtonV3>
  );
};

export default NavLoginOrSignUp;
